import React, { useEffect } from "react";
import conn from "../../assets/conn.png";
import { NavLink } from "react-router-dom";
import "./style.css";

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 2;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}
window.addEventListener("scroll", reveal);


function Connect() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (

    <>
      <div className="container-fluid connectImages reveal">
        <div className="row   boxConnect">
          <div className="col-6">
            <div className="connectTitle">Let's Connect Thing's Together</div>
            <NavLink className="but-but" to="/contact">
              Connect
            </NavLink>
          </div>
          <div className="col-6">
            <div>
              <img src={conn} id="image1" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Connect;
