import React, { Component } from "react";

import web from "../../assets/U1.jpg";
import "./Service.css";
import mob from "../../assets/ad.png";
import ecom from "../../assets/ecomm.png";
import api from "../../assets/api2.png";
import Connect from "../Connect/Connect";
import "../animateCss/style.css"
import { Helmet } from "react-helmet";

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 2;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}
window.addEventListener("scroll", reveal);

export default class Service extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <>
        <Helmet>
          <title>Perfetto Solutions | Services</title>
        </Helmet>
        <div className="container-fluid p-0">
          <div className=" top animateClassTop">
            <h2> Our Services</h2>
          </div>

          <div className="container">
            <div className="row section">
              <div className="col-md-7 order-2 order-md-1 animateClassLeft">
                <div className="head">Web Development</div>
                <div>
                  <div className="par">
                    <i class="fas fa-laptop icon"></i>
                    <b className="mx-3">
                      We build designs that support smooth navigation and are
                      aesthetically beautiful
                    </b>
                    <b>
                      Drive meaningful connections with powerful Web
                      applications :
                    </b>

                    <p>
                      Front-end of the web
                      or application is the part that users can interact and see.
                      If your website looks great, loads quickly, and work well
                      without any glitches, then you can assume the front-end
                      development of your site works well.
                      <br />
                      If you look behind
                      website development, there is a clean code in the back-end
                      that powers up all the mighty functionality. However, smooth
                      and intuitive navigation only supports the back-end code.
                      With front-end development, you can craft a look and feel
                      that compliments the code and creates an impact on your
                      users.
                      <br />
                      At Perfetto
                      Solutions, we provide front-end development services that
                      guarantee better usability with intuitive UI that reflects
                      the business data well and support smooth functionality
                      deployment. Keeping your core business requirement in mind,
                      we strategize precisely and add life to your design with
                      each element that we augment to the UI structure and design.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-5 col-sm-12 order-1 order-md-2">
                <div>
                  <img src={web} className="image animateClassRight" alt="" />
                </div>
              </div>
            </div>

            <div className="row section reveal">
              <div className="col-md-5 animateClassLeft col-sm-12">
                <div>
                  <img src={mob} className="image my-lg-2" alt="" />
                </div>
              </div>
              <div className="col-md-7 animateClassRight">
                <div className="head rightHeading">App Development</div>
                {/* <div className="head">App Development</div> */}
                <div className="par">
                  <p>
                    <i class="fa fa-mobile icon" aria-hidden="true"></i>
                    <b className="mx-3">
                      Develop apps that perform and drive revenue growth
                    </b>
                  </p>
                  <p>
                    <b>
                      Drive meaningful connections with powerful mobile
                      applications :
                    </b>
                  </p>
                  <p>
                    Mobile technology has
                    changed everybody’s life to a great extent. For companies,
                    it facilitates wider and unparalleled reach to the target
                    audience, enhances the service standards, better cooperation
                    and communication among employees.
                    <br />
                    Enterprise Mobility
                    adds liquidity to stationary enterprise processes, thus
                    enhancing the global reach. Developing enterprise mobile
                    apps is now an essential component of every enterprise
                    strategy.
                    <br />
                    <b>Our Mobile Api Development Services Include :</b>{" "}
                  </p>
                  <ul>
                    <li>Android App Development</li>
                    <li>App Prototype Development</li>
                    <li>iPad App Development</li>
                    <li>iphone App Development</li>
                    <li>IOS App Development</li>
                    <li>Smart Watch App Development</li>
                    <li>Hybrid Apps Development</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row reveal section">
              <div className="col-md-7 order-2 order-md-1 animateClassLeft ">
                <div className="head">API Development</div>
                <div className="par">
                  <p>
                    <i class="fas fa-code icon "></i>
                    <b className="mx-3">
                      Make smoother and hassle-free shifts in your application
                      with a robust and reliable API. Create experiences that
                      matters.
                    </b>
                  </p>
                  <p>
                    <b>Engage your audience with alluring graphics.</b>
                  </p>
                  <p>
                    API (Application
                    Programming Interface) is a significant part of any software
                    module, web solution, plugin, web design, or web application
                    as it lays the base standards for accessing a web-based
                    application.
                    <br />
                    As API is an
                    integral component that allows reliable and smooth
                    interaction between system hardware, databases, and software
                    components, it plays a major role in defining the success of
                    your application or a web tool.
                    <br />
                    To the users, only
                    the interface of the software is visible. However, API is
                    responsible for handling the programming interface by
                    integrating different applications. It functions in the
                    background so that can seamlessly use the software without
                    worrying about the flow of control between different modules
                    and applications. <br />
                    Powerful APIs are
                    not a result of an accident; it requires industry insight,
                    technical excellence, and a core understanding of the
                    business requirement. At TridhyaTech, we have a specialized
                    infrastructure an experienced team that delivers APIs with
                    legitimate and clean code design. We develop highly
                    customized APIs that are easy to implement and use,
                    well-documented, scalable, powerful, secure, and error-free.
                  </p>
                </div>
              </div>
              <div className="col-md-5 order-1 order-md-2 reveal animateClassRight">
                <div>
                  <img src={api} className="image" alt="" />
                </div>
              </div>
            </div>
            <div className="row reveal section ">
              <div className="col-md-5 animateClassLeft">
                <div>
                  <img src={ecom} className="image reveal" alt="" />
                </div>
              </div>
              <div className="col-md-7 animateClassRight">
                <div className="head rightHeading">E-Commerce Development</div>
                {/* <div className="head">E-Commerce Development</div> */}
                <div className="par">
                  <p>
                    <i class="fas fa-laptop icon "></i>
                    <b className="mx-3">
                      We build designs that support smooth navigation and are
                      aesthetically beautiful
                    </b>
                  </p>
                  <p>
                    <b>
                      Drive meaningful connections with powerful mobile
                      applications :
                    </b>
                  </p>
                  <p>
                    We have employed
                    bright and passionate individuals that are pros in making
                    complex eCommerce projects technically and aesthetically
                    impeccable. We cater our eCommerce web development services
                    to both B2B and B2C ventures and focus on ROI for our
                    clients as our ultimate success metric.
                    <br />
                    New IT technologies
                    have completely changed the way people shop in the 21st
                    century. Internet and mobile devices have made it possible
                    to purchase products or services at any time and in any
                    location As a result, a tremendous number of e-commerce
                    websites appeared on the world wide web and continue to
                    compete with each other.
                    <br />
                    Today, the eCommerce
                    market is growing infinitely. The yearly growth for the
                    eCommerce industry is approximately 23% according to
                    BigCommerce. eMarketer forecasted that global eCommerce
                    sales are expected to cross $4 trillion in 2020 and these
                    are just the statistics for the retail sector.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Connect />
        </div>
      </>
    );
  }
}
