import React, { Component } from 'react';
import testData from '../../testimonData';
import "./style.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


export default class Testimonial extends Component {
    render() {
        return (
            <div className='container-fluid rootBox'>
                <Carousel className='carsol' autoPlay={true} showIndicators={false} swipeable={true} infiniteLoop={true} >
                    {testData.map((data) => {
                        return (
                            <div className='dataBox'>
                                <div className='iconQuoteLeft' ><i class="fa fa-quote-left"></i></div>
                                <div className='description' >{data.description}</div>
                                <div className='iconQuoteRight' ><i class="fa fa-quote-right"></i></div>
                                <div className='designation' >{data.company}</div>
                            </div>
                        )
                    })}
                </Carousel>
            </div>
        )
    }
}
