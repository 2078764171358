import React, { Component } from "react";
import "./style.css";
import Connect from "../Connect/Connect";
import "../animateCss/style.css";
import aboutImg from "../../assets/full-stack-development.gif";
import CountUp from 'react-countup';
import { Helmet } from "react-helmet";

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 2;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}
window.addEventListener("scroll", reveal);

export default class About extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isVisible: false,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <>

        <Helmet>
          <title>Perfetto Solutions | About</title>
        </Helmet>
        <div className="container-fluid p-0">
          <div className=" top animateClassTop">
            <h2> About</h2>
          </div>
          <div className="row py-5">
            <div className="col-md-12">
              <h3 className="dispay-5 fw-bold ">
                Your one-stop solution for all your Digital needs!
              </h3>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-6 animateClassRight ">
                <img src={aboutImg} className="aboutImage" />
              </div>
              <div className="col-md-6 animateClassLeft">
                <p className="aboutPar">
                  Perfetto Solutions is a software development company founded in the year 2010 in Surendranagar, Gujarat. The founder of the company is the Late Mr. Hemal Shukla. He and his allies successfully ventured into Web Development, Software Application Designing, and Programming. Perfetto Solutions has progressed exceptionally and has accomplished more than 100 designing and developing projects on time.  </p>

                <p className="aboutPar">
                  The company is a sister concern of Shiv Computers which was established by Mr. Shukla in the year 1998. We teach an array of languages such as C, JAVA, PHP, Android, Python, and many more. Filled with tremendous achievement, Shiv Computer jumped into the corporate field as Perfetto Solutions in 2010.  </p>


              </div>

            </div>
            {/* OUR APROCH */}
            <div className="row">
              <div className="col-md-12">
                <p className="aboutPar">
                  We choose to execute what is best for our clients. Our primary goal is to offer end-to-end digital solutions to help businesses achieve their goals. Our technical enthusiasts include analysts, developers, designers, and testers who believe in offering optimal custom software development services to all businesses.
                </p>

              </div>
            </div>
            <div className="container reveal" >
              <div className="row ">
                <div className="col-3 centerbox">
                  <div className="numberBox" >
                    <div className="numberTitle">Students Trained</div>
                    <CountUp end={150} delay={1} duration={5} className="countNumber" suffix=" +" />
                  </div>
                </div>
                <div className="col-3 centerbox">
                  <div className="numberBox" >
                    <div className="numberTitle">Successful Projects </div>
                    <CountUp end={100} delay={1} duration={5} className="countNumber" suffix=" +" />
                  </div>
                </div>
                <div className="col-3 centerbox">
                  <div className="numberBox" >
                    <div className="numberTitle">Satisfied Clients</div>
                    <CountUp end={50} delay={1} duration={5} className="countNumber" suffix=" +" />
                  </div>
                </div>
                <div className="col-3 centerbox">
                  <div className="numberBox" >
                    <div className="numberTitle">Tech Savy Team Members</div>
                    <CountUp end={10} delay={1} duration={5} className="countNumber" suffix=" +" />
                  </div>
                </div>

              </div>
            </div>

            <div className="row" >
              <div className="col-md-12 approch">
                <div className="approchHeading">Our Approach :</div>
                <p className="">

                  Setting new benchmarks in the industry since 2010.
                  We hire or train bright students from Shiv Computers in programming and coding at Perfetto Solutions and offer them the necessary expertise to advance in their careers. We are delighted that many of our students are working for globally recognized IT organizations and MNCs.
                </p>
                <p>
                  We have become a stepping stone for ambitious and brilliant youngsters from the Surendranagar town. Along with it, we are supporting small and medium businesses to enter the online medium of business and use digital platforms to prosper further. We design very user-friendly and attractive websites and applications for such businesses at very reasonable charges.
                </p>
                <p>
                  Our range of services includes Mobile Apps Development, Web Development, API Development, and E-Commerce Development.
                </p>
                <p>Digitally and truly yours. <br />
                  Contact us to stay ahead in the digital world!</p>
                <div
                  className="reveal mb-5"
                  style={{
                    color: "#808080",
                    fontWeight: "720",
                    fontSize: "22px",
                  }}
                >
                  <i>
                    ” This is a tribute to my friend, philospher and guide. I
                    dedicated this to Mr.Hemal Shukla who has guided me always
                    and still he is my guiding angel. “
                  </i>
                </div>
              </div>
            </div>

            {/* <div className="container reveal" >
              <div className="row ">
                <div className="col-4 centerbox">
                  <div className="numberBox" >
                    <div className="numberTitle">Projects</div>
                    <CountUp end={100} delay={3} duration={5} className="countNumber" suffix=" +" />
                  </div>
                </div>
                <div className="col-4 centerbox">
                  <div className="numberBox" >
                    <div className="numberTitle">Clients</div>
                    <CountUp end={50} className="countNumber" suffix=" +" delay={3} duration={5} />
                  </div>
                </div>
                <div className="col-4 centerbox">
                  <div className="numberBox" >
                    <div className="numberTitle">Interns</div>
                    <CountUp end={150} className="countNumber" suffix=" +" delay={3} duration={5} />
                  </div>
                </div>
              </div>
            </div> */}

          </div>
          <Connect />
        </div>
      </>
    );
  }
}
