// import React, { Component } from 'react'
import React, { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import msg from "../../assets/deforge_phone_dribbble.gif";
import "./style.css";
import "../animateCss/style.css"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Audio, TailSpin } from "react-loader-spinner";
import { Helmet } from "react-helmet";

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 2;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}
window.addEventListener("scroll", reveal);

// export default class Contact extends Component {
//     render() {
export default function Contact() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [sub, setSub] = useState('');
  const [message, setMessage] = useState('');
  const [contact, setCotnact] = useState('');
  const [alertbox, setAlertbox] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  function sendEmail(e) {
    setIsLoading(true)
    e.preventDefault();
    // emailjs.send("service_knwzm0n", "template_hqrbyjo");

    emailjs.sendForm("service_knwzm0n", "template_hqrbyjo", e.target, "user_2n629GYKAqmghQdwuTZRh").then(res => {
      console.log(res);
      setEmail('')
      setName('')
      setCotnact('')
      setMessage('')
      setSub('')
      setAlertbox(true)
      setIsLoading(false)
      setInterval(() => {
        setAlertbox(false)
      }, 2000);
    }).catch(err => console.log(err));

  }

  return (
    <>
      <Helmet>
        <title>Perfetto Solutions | Contact Us</title>
      </Helmet>
      <div className="container-fluid p-0">
        <div className="top animateClassTop">
          <h2> Contact Us</h2>
        </div>
        <div>
          <div className="col-md-12  ">
            <iframe
              title="this is title"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3680.0520738990267!2d71.62891961496223!3d22.726305785103925!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395941657d89cd51%3A0xc36841c530c5b376!2sPERFETTO%20SOLUTIONS!5e0!3m2!1sen!2sin!4v1643779574931!5m2!1sen!2sin"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-xs-6 w3-animate-left">
              <div className="contactTitle">Get In Touch</div>
              <form className=" my-lg-5" onSubmit={sendEmail}>
                <div class="form">
                  <input type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} required />
                  <label for="name" class="label-name">
                    <span class="content-name">Name</span>
                  </label>
                </div>
                <div class="form">
                  <input type="email" id="email" name="user_email" required value={email} onChange={(e) => setEmail(e.target.value)} />
                  <label for="name" class="label-name">
                    <span class="content-name">Email</span>
                  </label>
                </div>
                <div class="form">
                  <input type="text" name="sub" required value={sub} onChange={(e) => setSub(e.target.value)} />
                  <label for="name" class="label-name">
                    <span class="content-name">Subject</span>
                  </label>
                </div>
                <div class="form">
                  <input type="text" name="contact" required value={contact} onChange={(e) => setCotnact(e.target.value)} />
                  <label for="name" class="label-name">
                    <span class="content-name">Contact</span>
                  </label>
                </div>
                <div class="form">
                  <input
                    type="text"
                    name="message"
                    required
                    autocomplete="off"
                    value={message} onChange={(e) => setMessage(e.target.value)}
                  />
                  <label for="name" class="label-name">
                    <span class="content-name">Message</span>
                  </label>
                </div>

                {isLoading ?
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center'
                  }} >
                    <TailSpin
                      height="25"
                      width="50"
                      color='orange'
                      ariaLabel='loading'
                    /></div> :
                  <button type="submit" className=" button">
                    Submit
                  </button>}
              </form>
              {alertbox ?
                <div className="alert alert-success alert-dismissible fade show">
                  <strong>Thank You !</strong> Your Query Submited Successfully.
                </div>
                : null
              }

            </div>
            <div className="col-md-6 col-xs-6 reveal w3-animate-right">
              <img src={msg} className="cnt-msg mt-2 mb-2" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
// }
