import React, { Component } from 'react';
import { BrowserRouter, Routes, Route, NavLink, Link } from 'react-router-dom';
import Home from "../Home/Home";
import Contact from "../Contact/Contact";
import About from "../About/About";
import Technology from "../Technology/Technology";
import ps from "../../assets/ps.png";
import "./nav.css";
import Service from "../Service/Service";
import $ from "jquery";

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      show: false,
      colorChange: false,
    }
  }

  handleNavClick = () => {
    this.setState({ show: false });
    window.scrollTo(0, 0)
  };

  render() {
    window.addEventListener('scroll', (event) => {
      if (window.scrollY < 100) {
        $("#navBar").removeClass("colorChange");
      } else {
        $("#navBar").addClass("colorChange");
      }
    });
    return (
      <BrowserRouter>

        <nav className='navbar navbar-expand-lg navbar-light bg-light fixed-top' id="navBar">
          <div className="container-fluid ">
            <div>
              <a className="navbar-brand" href="/">
                <img src={ps} style={{ width: '200px', height: '80px' }} alt="Perfetto Solutions Logo" className="logoNavbar" />
              </a>
            </div>
            <button
              className="navbar-toggler" type="button"
              onClick={() => this.setState({ show: !this.state.show })}
            >
              <span className="dash">
                <i class="fa fa-chevron-circle-down" aria-hidden="true"></i>
              </span>
            </button>
            <div
              className={`collapse navbar-collapse  ${this.state.show ? "show" : ""}`} id="navbarSupportedContent" >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink className="nav-link" to="/" onClick={this.handleNavClick}>Home</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" onClick={this.handleNavClick} to="/about">About</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" onClick={this.handleNavClick} to="/service">Service</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" onClick={this.handleNavClick} to="/technology">Technology</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" onClick={this.handleNavClick} to="/contact">Contact</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="blankNavSpace">Blank Space</div>
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/about' element={<About />}></Route>
          <Route path='/service' element={<Service />}></Route>
          <Route path='/technology' element={<Technology />}></Route>
          <Route path='/contact' element={<Contact />}></Route>
        </Routes>
      </BrowserRouter>
    );
  }
}
export default App;