import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Footer from './Components/Footer/Footer'
import NavbarComp from './Components/navbar/NavbarComp';

function App() {
  return (
    <div className="App">

      <NavbarComp  />
      
      <Footer />
    </div>

  );
}

export default App;
