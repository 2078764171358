import React, { useEffect, useState } from "react";
import Connect from "../Connect/Connect";
import Data from "../../data";
import "./style.css";
import "../animateCss/style.css"
import { Helmet } from "react-helmet";

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 2;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}
window.addEventListener("scroll", reveal);

// export default class Technology extends Component {
export default function Technology() {
  const [items, setItems] = useState(Data);

  const filteredItem = (category) => {
    const updatedItems = Data.filter((curElem) => {
      return curElem.category === category;
    });

    setItems(updatedItems);
  };
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  // render() {
  return (
    <>
      <Helmet>
        <title>Perfetto Solutions | Technology</title>
      </Helmet>
      <div className="container-fluid p-0">
        <div className="top animateClassTop">
          <div className="title">Our Technology</div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="menu-tab">
          <button
            className="filter-btn mx-2 mt-2"
            name="all"
            onClick={() => setItems(Data)}
          >
            {" "}
            All{" "}
          </button>
          <button
            className="filter-btn mx-2 mt-2"
            name="e-comm"
            onClick={() => filteredItem("e-com")}
          >
            E-Commerce
          </button>
          <button
            className="filter-btn mx-2 mt-2"
            onClick={() => filteredItem("web")}
          >
            Web Development
          </button>
          <button
            className="filter-btn mx-2 mt-2"
            onClick={() => filteredItem("app")}
          >
            Mobile App Development
          </button>
          <button
            className="filter-btn mx-2 mt-1"
            onClick={() => filteredItem("api")}
          >
            API Development
          </button>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {items.map((elem) => {
            const { image, name } = elem;
            return (
              <div className="item1 col-md-4 col-6">
                <div className="item-inside">
                  <img
                    src={image}
                    alt=""
                    className="animateClassZoom techImage"
                  />
                  <div className="imageName">{name}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Connect />
      {/* FooterStat */}

      {/* FooterEnd */}
    </>
  );
}
// }
