const testData = [
    {
        id: 1,
        description: `We've been trying to put together a functional website since I began my practice in April of 2012. I am happy to say we finally hired the Flatworld team and they've worked closely with us throughout the process, staying on task, on target, and on budget. I also appreciate their quick and courteous responses. I highly recommend their service!`,
        company: 'Mod Ceramic Industries Ltd.',
    },

    {
        id: 2,
        description: `I am really impressed by the quality of services I received from Flatworld Solutions. You were right on schedule, charged reasonable prices, were professional and courteous in dealings, and delivered items well before time. I have got a good e-commerce site for my products. My revenue has increased because of Flatworld and I will definitely use your services again.`,
        company: 'Green Era Multitech LLP.',
    },

    {
        id: 3,
        description: `I will admit - originally the thought of outsourcing scared the hell out of me. As a business owner I am used to control and always having my finger on the pulse of my employees and contractors. Flatworld has delivered great results and has convinced me of the value of outsourcing. In the beginning of the relationship between us and Flatworld, there were times when it was difficult to communicate but if you can just get through the first week or so everything starts to come together.`,
        company: 'Rudra Enterprise.',
    },

]

export default testData;

