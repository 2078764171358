const Data = [
    {
        id: 1,
        image: 'image/magento.png',
        category: 'e-com',
        name: 'Magento'
    },
    {
        id: 2,
        image: "image/react.png",
        category: 'web',
        name: 'React Js'
    },
    {
        id: 16,
        image: "image/php.png",
        category: "web",
        name: 'Php'
    },
    {
        id: 3,
        image: "image/android.png",
        category: "app",
        name: 'Android'
    },
    {
        id: 4,
        image: "image/python.png",
        category: 'app',
        name: 'Python'
    },
    {
        id: 5,
        image: "image/java-logo.png",
        category: 'web',
        name: 'Java'
    },
    {
        id: 6,
        image: "image/net.png",
        category: 'web',
        name: 'Asp.Net'
    },
    {
        id: 7,
        image: "image/presta.png",
        category: 'e-com',
        name: 'Presta Shop'
    },
    {
        id: 8,
        image: "image/woocom.png",
        category: 'e-com',
        name: 'Wordpress'
    },
    {
        id: 9,
        image: "image/cake.png",
        category: 'web',
        name: 'Cake Php'
    },
    {
        id: 10,
        image: "image/symfony.png",
        category: 'e-com',
        name: 'Symfony'
    },
    {
        id: 11,
        image: "image/iosapp.png",
        category: 'app',
        name: 'IOS'
    },
    {
        id: 12,
        image: "image/react.png",
        category: 'app',
        name: 'React Native'
    },
    {
        id: 13,
        image: "image/java-logo.png",
        category: 'api',
        name: 'Java'
    },
    {
        id: 14,
        image: "image/net.png",
        category: 'api',
        name: 'Asp.Net'
    },
    {
        id: 15,
        image: "image/php.png",
        category: "api",
        name: 'Php'
    },

]

export default Data;

