import React, { Component } from "react";
import team from "../../assets/team.jpg";
import slide1 from "../../assets/sl1";
import mobi from "../../assets/msg.jpg";
import web from "../../assets/U1.jpg";
import api from "../../assets/ad.png";
import ecom from "../../assets/e-commerce.svg";
import grow from "../../assets/grow.jpg";
import layer from "../../assets/layer-2.png";
import elite from "../../assets/headphones.png";
import mob from "../../assets/smartphone-1.png";
import speed from "../../assets/download-speed-1.png";
import Connect from "../Connect/Connect";
import "./style.css";
import "../animateCss/style.css";
import Testimonial from "../testimonials.js";
import { Helmet } from "react-helmet";
function reveal() {


  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 2;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}
window.addEventListener("scroll", reveal);

export default class Home extends Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {

    return (
      <>

        <Helmet>
          <title>Perfetto Solutions | Home</title>
        </Helmet>
        <div className="container-fluid p-0">
          <div
            id="carouselExampleControls"
            className="carousel slide carousel-fade"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner px-5" id="slider">
              <div className="carousel-item active">
                <div className=" row">
                  <div className="col-md-6 order-2 order-md-1">
                    <div className="animateClassLeft">
                      <h1 className="my-lg-5 heading1">
                        The Next Generation Trust Company
                      </h1>
                      <p className="text">
                        Perfetto Solutions is a full-service software
                        development and Mobile App company based in India. Our
                        range of services include Mobile Apps development and
                        Web Development{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 order-1 order-md-2">
                    <img
                      src={slide1}
                      className="rounded d-block w-100 animateClassRight"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col-md-6 order-2 order-md-1">
                    <div className="animateClassLeft" >
                      <h1 className="heading1">
                        An Outsourcing Team that works for many MNC .
                      </h1>
                      <p className="text">
                        We work with starts-ups, small-mid sized company and
                        fortune 50+ companies.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 order-1 order-md-2">
                    <img
                      src={team}
                      className="rounded d-block w-100 animateClassRight"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col-md-6 order-2 order-md-1 animateClassLeft">
                    <div>
                      <h1 className="heading1">Build Your Own Offshore Team</h1>
                      <p className="text">
                        Expand your business with exceptional offshore
                        development team. Available Full-Time, Part-Time and
                        Contract basis.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 order-1 order-md-2">
                    <img
                      src={grow}
                      className="rounded d-block w-100 animateClassRight"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="container"
            style={{ minWidth: "360", maxWidth: "1320" }}
          >
            <div className="row ">
              <div className="col-md-12">
                <div className="head">
                  <h1 className="my-4">About Us</h1>
                </div>
                <div className="paragraph ">
                  <p>
                    Perfetto Solutions is a software development company founded in the year 2010 in Surendranagar, Gujarat. The founder of the company is the Late Mr. Hemal Shukla. He and his allies successfully ventured into Web Development, Software Application Designing, and Programming. Perfetto Solutions has progressed exceptionally and has accomplished more than 100 designing and developing projects on time.
                  </p>
                </div>
              </div>
            </div>

            <div className="row  my-lg-5">
              <div className="col-md-3  my-3">
                <div className="card reveal">
                  <img
                    src={web}
                    height="205px"
                    className="card-img-top"
                    alt="..."
                  />
                  <div class="card-body">
                    <p className="card-text">Web Development</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3  my-3">
                <div class="card reveal" style={{ width: "18" }}>
                  <img
                    src={mobi}
                    height="205px"
                    class="card-img-top"
                    alt="..."
                  />
                  <div class="card-body">
                    <p class="card-text">App Development</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 my-3">
                <div class="card reveal" style={{ width: "18" }}>
                  <img
                    src={api}
                    height="205px"
                    class="card-img-top"
                    alt="..."
                  />
                  <div class="card-body">
                    <p class="card-text">API Development</p>
                  </div>
                </div>
              </div>

              <div className="col-md-3  my-3">
                <div className="card reveal" style={{ width: "18" }}>
                  <img
                    src={ecom}
                    height="205px"
                    class="card-img-top bg-light"
                    alt="..."
                  />
                  <div class="card-body">
                    <p class="card-text">E-commerce Development</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid servicesBox">
            <div className="container">
              <div className="row  my-lg-5">
                <div className="col-md-3">
                  <div className="logo">
                    <img src={layer} alt="" className="serviceImages" />
                  </div>
                  <div className="read my-4">
                    A modular design{" "}
                    <b>is an approach for product designing </b>
                    which is used to produce a complete product by integrating
                    or combining smaller parts that are independent of each
                    other.
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="logo">
                    <img src={elite} alt="" className="serviceImages" />
                  </div>
                  <div className="read my-4">
                    In addition to <b> 24×7 </b> phone support for critical
                    issues, the Web-based support includes access to a
                    comprehensive library of technical notes, white papers and
                    other resources and online support requests.
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="logo">
                    <img src={mob} alt="" className="serviceImages" />
                  </div>
                  <div className="read my-4">
                    It is a site{" "}
                    <b>
                      that is ready to be viewed on a smart phone or tablet.{" "}
                    </b>
                    The whole site fits on the screen, scrolling right to left
                    is not required, and the text and pictures re-flow and
                    re-size dynamically.
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="logo">
                    <img src={speed} alt="" className="serviceImages" />
                  </div>
                  <div className="read my-4">
                    {" "}
                    We Provide a High Performance Application Low Web latency
                    Application ( meaning low Page Loading times)
                    <b>
                      {" "}
                      Application that can serve ever increasing number of users
                    </b>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Testimonial />

          <Connect />
        </div>
      </>
    );
  }
}
